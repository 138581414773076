import { Cart, CartItem } from "../contexts/cart/types";
import {
  WineClub,
  WineClubFrequency,
  WineClubVariant,
} from "../../src/contexts/wine-clubs/types";
import {
  segmentProductFromCartItem,
  segmentProductOffer,
} from "../transformers/segment/offer";

import { ButtonPosition } from "../contexts/cart/types";
import { OfferHit } from "../components/product-collection/ProductCollection";
import { SegmentProductWithAlgoliaInsights } from "../types/segment-types";
import { EventSubType, EventType, ShareMethod } from "../types";
import { VinoDataOffer } from "../types/vino-data-types";

const trackingEventNames = [
  "Product List Viewed",
  "Product List Filtered",
  "Product Clicked",
  "Recommended Product Clicked",
  "Recommended Product Added",
  "Product Added",
  "Product Added - Ineligible",
  "Sorted By",
  "Products Searched",
  "Product Filtered",
  "Product Viewed",
  "Product Removed",
  "View Cart",
  "Wine Club Selected",
  "Subscription Started",
  "Automatic Remove Item From Cart",
  "Automatic Update Cart Item Quantity",
  "Show More Wines Clicked",
  "Show More Whisky Clicked",
  "One Click Checkout",
  "Add Filter Item",
  "Remove Filter Item",
  "Product Quantity Increased",
  "Product Quantity Decreased",
  "Referral Shared",
  "Coupon Entered",
  "Coupon Applied",
  "Coupon Denied",
  "Added to Favourites",
  "Removed from Favourites",
  "Join Clicked",
  "Login Clicked",
  "Login on Paywall Clicked",
  "Paywall Shown",
  "Cart Dropdown Viewed",
] as const;
export type TrackingEventName = typeof trackingEventNames[number];

export const trackWineClubEvent = (
  eventName: TrackingEventName,
  club: WineClub,
  frequency: WineClubFrequency,
  memberId: string,
  email: string,
  variant: WineClubVariant
): void => {
  const data = {
    club: club.label,
    frequency: frequency.label,
    market: process.env.VM_MARKET,
    user_id: memberId,
    email,
    variant: variant?.label,
    value: variant?.price,
    event_category: "Wine Club",
    transaction_id: `${memberId}-${club.label}-${frequency.label}-${variant?.label}`,
    event_label: `${club.label} ${variant?.label} ${frequency.label}`,
  };
  if (typeof window !== "undefined") {
    window.analytics.track(eventName, data);
  }
};

export const trackOfferListEvent = (
  eventName: TrackingEventName,
  offers: Array<OfferHit>,
  nbHits?: number
): void => {
  const data = {
    index: localStorage.getItem("algoliaIndexName"),
    search_index: localStorage.getItem("algoliaIndexName"),
    queryID: localStorage.getItem("queryID"),
    products: offers.map((offer, index) => {
      return segmentProductOffer(offer, index);
    }),
    hits_returned: nbHits,
  };

  if (
    typeof window !== "undefined" &&
    typeof window.analytics !== "undefined"
  ) {
    window.analytics.track(eventName, data);
  }
};

export const trackOfferDetailsEvent = (
  eventName: TrackingEventName,
  offer: OfferHit & EventData,
  buttonPosition?: ButtonPosition,
  queryID?: string
): void => {
  const data: SegmentProductWithAlgoliaInsights = {
    customer_group: offer?.customer_group,
    ...segmentProductOffer(offer),
    button_position: buttonPosition,
    queryID: queryID,
    query_id: queryID,
  };

  if (
    typeof window !== "undefined" &&
    typeof window.analytics !== "undefined"
  ) {
    window.analytics.track(eventName, data);
  }
};

export const trackOfferSearchEvent = (
  eventName: TrackingEventName,
  query: string,
  searched_from: string,
  nbHits: number
): void => {
  if (
    typeof window !== "undefined" &&
    typeof window.analytics !== "undefined"
  ) {
    window.analytics.track(eventName, {
      query,
      hits_returned: nbHits,
      searched_from,
    });
  }
};

export const trackOfferFilterEvent = (
  eventName: TrackingEventName,
  searchState: any
): void => {
  const refinements = searchState?.refinementList;
  if (refinements) {
    const refinementKeys = Object.keys(refinements);
    const filters = refinementKeys
      .map(
        (x) =>
          refinements[x][0] &&
          refinements[x].map((y) => {
            return { type: x, value: y };
          })
      )
      .flat();
    const data = {
      index: localStorage.getItem("algoliaIndexName"),
      search_index: localStorage.getItem("algoliaIndexName"),
      eventType: EventType.View,
      queryID: localStorage.getItem("queryID"),
      filters,
    };
    if (
      typeof window !== "undefined" &&
      typeof window.analytics !== "undefined"
    ) {
      window.analytics.track(eventName, data);
    }
  }
};

export const trackOfferViewEvent = (
  eventName: TrackingEventName,
  offer: VinoDataOffer
): void => {
  const data = segmentProductOffer(offer);
  if (
    typeof window !== "undefined" &&
    typeof window.analytics !== "undefined"
  ) {
    window.analytics.track(eventName, data);
  }
};

export const trackProductRemovedEvent = (
  eventName: TrackingEventName,
  cartItem: CartItem,
  cartId: string
): void => {
  const data = segmentProductFromCartItem(cartItem, cartId);
  if (
    typeof window !== "undefined" &&
    typeof window.analytics !== "undefined"
  ) {
    window.analytics.track(eventName, data);
  }
};

export const trackCartCouponEvent = (
  eventName: TrackingEventName,
  location: string,
  cart: Cart,
  couponCode: string,
  discount?: number,
  error?: string
): void => {
  let couponData = {
    cart_id: cart.id,
    coupon_id: couponCode,
    source: location,
  };
  if (eventName === "Coupon Applied") {
    couponData["discount"] = discount;
  }

  if (eventName === "Coupon Denied") {
    couponData["reason"] = error;
  }
  if (
    typeof window !== "undefined" &&
    typeof window.analytics !== "undefined"
  ) {
    window.analytics.track(eventName, couponData);
  }
};

export const trackViewCartEvent = (
  eventName: TrackingEventName,
  cart: Cart
): void => {
  const data = {
    cart_id: cart.id,
    products: cart.lineItems.map((x) => segmentProductFromCartItem(x)),
  };
  if (
    typeof window !== "undefined" &&
    typeof window.analytics !== "undefined"
  ) {
    window.analytics.track(eventName, data);
  }
};

const oneClickCheckoutEventStatus = {
  clicked: "Clicked",
  buying:
    "Assigning biilling address, calculating shipping fee and creating checkout order",
  preparing:
    "Checking allowed payment methods, preparing payment instrument and generating payment token",
  initiating: "Initial payment attempt",
  success: "Payment Success",
  completed: "Order Completed",
};

type oneClickCheckoutEventStatusType =
  | "clicked"
  | "buying"
  | "preparing"
  | "initiating"
  | "success"
  | "completed";

export const trackOneClickCheckout = (
  eventName: TrackingEventName,
  cart: Cart,
  status: oneClickCheckoutEventStatusType
): void => {
  const data = {
    label: oneClickCheckoutEventStatus?.[status],
    category: "one-click-checkout",
    action: "one-click-checkout",
    cart_id: cart.id,
    products: cart.lineItems.map((x) => segmentProductFromCartItem(x)),
  };
  console.log("trackOneClickCheckout DATA", data);
  if (
    typeof window !== "undefined" &&
    typeof window.analytics !== "undefined"
  ) {
    window.analytics.track(eventName, data);
  }
};

export type EventData = {
  search_index?: string | null;
  eventType?: EventType;
  eventSubType?: EventSubType;
  position?: number;
  product_id?: string;
  queryID?: string | null;
};

export const mockTrackEvent = (
  _eventName: TrackingEventName,
  _eventData?: EventData
): void => {
  console.info("Track event was called without being defined");
};

export const trackAutomaticCartitemEvent = ({
  eventName,
  cart,
  idDashname,
  actiontype = "remove",
}: {
  eventName: TrackingEventName;
  cart: Cart;
  idDashname: string;
  actiontype?: "remove" | "update";
}): void => {
  const filteredProducts = cart.lineItems.find(
    (x) => `${x.productId}-${x.name}` === idDashname
  );

  const data = {
    label: eventName || "Automatic Remove Item From Cart",
    category:
      actiontype === "remove"
        ? "auto-remove-item-from-cart"
        : "auto-update-cart-item-quantity",
    action:
      actiontype === "remove"
        ? "auto-remove-item-from-cart"
        : "auto-update-cart-item-quantity",
    cart_id: cart.id,
    products: [filteredProducts],
  };

  if (
    typeof window !== "undefined" &&
    typeof window.analytics !== "undefined"
  ) {
    window.analytics.track(eventName, data);
  }
};

export const trackAddRemoveFilterEvent = ({
  eventType,
  searchState,
  filter,
}: {
  eventType: "add" | "remove";
  searchState: any;
  filter: {
    eventType?: string;
    type: string;
    value: string;
  };
}): void => {
  const refinements = searchState?.refinementList;
  const eventName: TrackingEventName =
    eventType === "add" ? "Add Filter Item" : "Remove Filter Item";

  if (refinements && filter) {
    const refinementKeys = Object.keys(refinements);
    const filters = refinementKeys
      .map(
        (x) =>
          refinements[x][0] &&
          refinements[x].map((y) => {
            return { type: x, value: y };
          })
      )
      .flat();
    const data = {
      index: localStorage.getItem("algoliaIndexName"),
      search_index: localStorage.getItem("algoliaIndexName"),
      queryID: localStorage.getItem("queryID"),
      filters,
      filter: {
        ...filter,
        eventType: eventType,
      },
    };
    if (
      typeof window !== "undefined" &&
      typeof window.analytics !== "undefined"
    ) {
      window.analytics.track(eventName, data);
    }
  }
};

export const trackProductSortedByEvent = (
  eventName: TrackingEventName,
  label: string
): void => {
  if (
    typeof window !== "undefined" &&
    typeof window.analytics !== "undefined"
  ) {
    window.analytics.track(eventName, { sortedBy: label });
  }
};

export const trackReferralSharedEvent = (method: ShareMethod): void => {
  if (
    typeof window !== "undefined" &&
    typeof window.analytics !== "undefined"
  ) {
    window.analytics.track("Referral Shared", { method });
  }
};

export const trackFavouriteEvent = (
  eventName: TrackingEventName,
  offer: {
    sku_code: string;
    offer_name: string;
    wine_name: string;
  }
): void => {
  const data = { ...offer };
  if (
    typeof window !== "undefined" &&
    typeof window.analytics !== "undefined"
  ) {
    window.analytics.track(eventName, data);
  }
};
